.teamcard-parent {
    width: 350px;
    height: 190px;
    border: 1px solid var(--white);
    border-radius: 5px;
    padding: 10px;
    display: flex;
    box-shadow: var(--shadow);
    position: relative;
    background: #c4c2c21e;
    .delete-btn 
    {
        position: absolute;
        top: 0;
        right: 0;
        padding: 8px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        cursor: pointer;
        border: none;
        background-color: white;
        color: rgba(119, 28, 28, 0.582);
        &:hover 
        {
            color: red;
        }
    }
    .teamcard-left {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 10px 0px;
        
      
         .task-status 
         {
            
            display: flex;
            flex-direction: column;
            padding: 5px;
            font-weight: 500;
            width: 100%;
            gap: 2px;
            span 
            {
                width: 100% !important;
                height: 100%;
                padding: 2px 0px;
                cursor: pointer;
            }
         }
        p {
            font-size: var(--large-font);
            color: var(--black);
            font-weight: 500;
            text-transform: capitalize;
        }
.team-name 
{
    text-decoration: none;
    color: var(--black);
    font-size: 20px;
    font-weight: 500;
}
    }

    .teamcard-right {
        text-decoration: none;
        width: 40%;
        height: 100%;
        border-radius: 5px;
        background: #0000001e;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span 
        {
            color: var(--black);
            font-size: 40px;

        }
        p 
        {
            color: var(--black);
            font-size: var(--small-font);
        }
    }
}
