.delete-wrapper {
    width: 100% !important;
    height: 100svh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in-out;
    z-index: 10000;
    .delete-box {
        background-color: #fff;
        padding: 20px;
        border-radius: 5px;
        box-shadow: var(--shadow);
        animation: fadeIn 0.3s ease-in-out;
        padding: 50px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        .btns {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            .dlt-btn {
                padding: 3px 15px;
                font-size: var(--small-font);
                cursor: pointer;
            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}