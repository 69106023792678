.legend-layout {
    position: fixed;
    width: 100%;
    height: 100svh;
    background: #0000009a;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    padding: 20px;
    margin: auto;

    .form-box {
        background: white;
        width: auto;
        height: auto;
        padding: 10px;
        border-radius: 10px;


        .close-btn {
            display: flex;
            width: 100%;
            justify-content: flex-end;
    
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                padding: 5px;
                border: none;
                font-size: var(--l-font);
                color: var(--black);
                cursor: pointer;
                font-weight: 500;
            }
        }
    }

}