.teams-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    .teams-container {
        width: fit-content;
        height: fit-content;
        display: flex;
        gap: 20px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        .teamcard-add {
            width: 350px;
            height: 190px;
            box-shadow: var(--shadow);
            border-radius: 5px;
            padding: 5px;

            .teamcard-add-inner {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                background: #0000001e;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 90px;
                text-decoration: none;
                color: var(--black);
                cursor: pointer;
            }
        }
    }


}