.login-parent {
    width: 100%;
    height: 100svh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../assets/login.webp");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

    .bg-overlay {

        position: absolute;
        width: 100%;
        height: 100%;
        top: 0%;
        left: 0%;
        background-color: rgba(0, 0, 0, 0.781);
    }

    form {
        padding: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border-radius: 5px;

        input,
        .pass-box {
            width: 250px;
            height: 40px;
            font-size: 16px;
            padding: 0px 10px;
            border-top: 0px;
            border-left: 0px;
            border-right: 0px;
            border-bottom: 1px solid white;
            outline: none;
            background-color: transparent;
            color: white !important;
            display: flex;
            justify-content: center;
            align-items: center;

            &::placeholder {
                color: whitesmoke;
            }

            .pass 
            {
                padding: 0px;

            }

            span 
            {
                cursor: pointer;
            }
        }
        .pass-box 
        { 
            input 
            {
                border-bottom: none !important;
            }
      
        }
        
    }
}
