.add_member_parent {
    width: 100%;
    height: 100svh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .addmember-container {
        background-color: #fff;
        padding: 10px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        .exit-btn 
        {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            a {
                background: var(--black);
                width: fit-content;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                padding: 5px;
                font-size: var(--xl-font);
                color: var(--white);
                cursor: pointer;
            }
        }

        form {
            display: flex;
            width: 550px;
            height: fit-content;

            .form-left {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 90%;
                gap: 15px;

                .input,
                .select {
                    width: 90% !important;
                    // height: 40px !important;
                    gap: 10px;
                    background: var(--accent-grey);
                    border: none;
                    // padding: 5px 15px;
                    border-radius: 5px;
                    font-size: var(--small-font);
                }

                .submit-member-btn {
                    width: 90% !important;
                    height: 50px;

                }
            }

            .form-right {
                background: var(--accent-grey);
                display: flex;
                justify-content: center;
                align-items: center;
                flex-grow: 1;
                width: 50%;
                position: relative;
                border-radius: 5px;

                .file-input {
                    position: absolute;
                    z-index: 1;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    opacity: 0;
                    cursor: pointer;

                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover !important;
                    object-position: center center !important;

                }

                label {
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    span {
                        margin-bottom: 10px;
                    }
                }
            }

            .drag-drop-area {
                width: 100%;
                height: 100%;
                padding: 20px;
                text-align: center;
                cursor: pointer;
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 120px;
                color: var(--text);
                flex-direction: column;
                gap: 10px;

                span {
                    font-size: var(--small-font);
                    color: #707070;
                }
            }
        }
    }
}