.sidebar-wrapper {
    width: 210px;
    background: var(--blue);
    min-height: 100svh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    gap: 20px;

    .sidebar-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        h2 {
            margin: auto;
            width: 80%;
            text-align: center;
            font-weight: 400;
            color: var(--white);
            border-bottom: 2px solid white;
            font-size: 20px;
        }

        .img-logo-div {
            margin: auto;
            background-color: white;
            width: fit-content;
            border-radius: 10px !important;
            overflow: hidden;
            margin-bottom: 20px;

            .img-logo {

                width: 120px;
                height: 70px;
                background-color: white;
                background-position: center !important;
                background-size: contain !important;
                background-repeat: no-repeat !important;
                background: url("../assets/logo.jpg");

            }
        }


        .manager {
            width: 80%;
            // border: 1px solid white;
            background-color: var(--dark-blue);
            display: flex;
            border-radius: 5px;
            margin: auto;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            user-select: none;
            color: var(--white);

            .manager-toggle {
                width: 80%;

                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 5px 0px;
                cursor: pointer;
                gap: 10px;

                a {
                    text-decoration: none;
                    color: var(--white);
                }

                span {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .manager-toggle1 {
                width: 80%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 5px 0px;
                cursor: pointer;
                gap: 10px;

                a {
                    text-decoration: none !important;
                    color: var(--white);
                }

                span {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transform: rotateX(180deg);
                }
            }

            .manager-menus {
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                overflow: hidden;
                height: auto;
                transition: all 0.3s ease;

                a {
                    color: var(--white);
                    padding: 8px 0px 8px 30px;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    cursor: pointer;
                    text-decoration: none;
                    font-size: var(--small-font);

                    &:hover {
                        background-color: var(--white);
                        color: var(--dark-blue);
                    }
                }
            }

            .manager-menus1 {
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
                overflow: hidden;
                height: 0px !important;
                transition: all 0.3s ease;

                a {
                    color: var(--white);
                    padding: 0px 0px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
            }

        }

    }

    .box-handler {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .logout-btn {
            width: 80%;
            padding: 8px 0px;
            font-size: var(--small-font);
            border-radius: 5px;
            cursor: pointer;
            border: none;
            background: var(--dark-blue);
            color: var(--white);
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;

            span {
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: var(--mid-font);
            }
        }

        .user-text {
            color: var(--white);
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            height: fit-content;
            align-items: center;
            justify-content: center;
            padding: 10px;
        }

        .btn-update {
            text-align: center;
            width: 100% !important;
            padding: 8px 0px;
            font-size: var(--small-font);
            border-radius: 5px;
            cursor: pointer;
            color: var(--white);
            text-decoration: none;
            position: relative;
        }
    }

}