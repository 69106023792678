.employee-add 
{
    width: 100%;
    height: 100svh;
    background: #0000009a;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .form-container 
    {
        padding: 10px;
        border: 1px solid white;
        width: 50%;
        height: fit-content;
        background-color: var(--white);
        border-radius: 5px;
        box-shadow: var(--shadow);
        p 
        {
            background: var(--black);
            float: right;
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            padding: 5px;
            font-size: var(--xl-font);
            color: var(--white);
            cursor: pointer;

        }

        form 
        {
            height: fit-content;
            color: var(--black);
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            margin-top: 50px;
            input
            {
                width: 100%;
                height: 50px;
                padding: 0px 10px;
                border-radius: 5px;
                border: 1px solid #dadada;
                font-size: 16px;
                outline: none;
                &:focus 
                {
                   border: 2px solid #2684ff;
                }
            }
            .select 
            {
                width: 100%;
            }
        }
    }
}