.addentery-parent {
    position: fixed;
    width: 100%;
    min-height: 100svh;
    background: #0000009a;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: scroll !important;

    .addentery-container {
        max-width: 800px;
        overflow-y: scroll;
        height: fit-content;
        background-color: var(--white);
        padding: 20px;
        border-radius: 5px;
        height: 90svh !important;
        overflow-y: auto;

        .back-btn {
            background: var(--black);
            float: right;
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            padding: 5px;
            font-size: var(--xl-font);
            color: var(--white);
            cursor: pointer;

        }

        form {
            height: fit-content;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: var(--gap);
            text-transform: capitalize;

            .count-box {
                width: fit-content;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 288.8px;
                height: 55px;
                position: relative;
                border: 1px solid #a5a5a5;
                border-radius: 5px;

                .inc-dec-btn {
                    border: 2px solid;
                    font-size: 24px;
                    width: 50px;
                    border-radius: 5px;
                    color: #9d9fa0;
                    cursor: pointer;

                    &:hover 
                    {
                        color: #1873CD;
                    }
                    
                }

                .count-btn {
                    width: 20%;
                    padding: 0px 20px !important;
                    font-size: 26px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    user-select: none;
                    color: #4b4b4b;
                }

                .repeat {
                    position: absolute;
                    left: 10px;
                    top: -11px;
                    font-size: 0.8rem !important;
                    opacity: 1;
                    background: #fff;
                    color: #575757;

                }
            }

            .text-input {
                width: 40%;
                padding: 0px !important;
                margin: 0px !important;

            }

            .submit-btn {
                width: 40%;
                height: 56px;
                padding: 16.5px;
            }
        }
    }
}