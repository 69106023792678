.completed-row {
    background-color: rgb(243, 255, 243);
    color: rgb(8, 37, 8);
    font-weight: 500;
    &:hover 
    {
      background-color: rgb(228, 253, 228) !important;
      color: rgb(8, 37, 8);
    }
    .handle-buttons
    {
       button 
       {
        color: rgb(8, 37, 8)!important;
       }
    }
  }
.delayed-row {
    background-color: rgb(255, 240, 240);
    color: rgb(104, 4, 4);
    font-weight: 500;
    &:hover 
    {
      background-color: rgb(252, 226, 226) !important;
      color: rgb(104, 4, 4);
    }
    .handle-buttons
    {
       button 
       {
        color: rgb(104, 4, 4) !important;
       }
    }
  }
.pending-row {
    background-color: rgb(255, 252, 238) ;
    color: rgb(99, 89, 0);
    font-weight: 500;
    &:hover 
    {
      background-color: rgb(253, 244, 201) !important;
      color: rgb(99, 89, 0);;
    }
    .handle-buttons
    {
       button 
       {
        background-color: rgb(255, 252, 238);
        color: rgb(99, 89, 0);
       }
    }
  }

  .team-sheet 
  {
    display: flex;
    text-transform: capitalize;
  }
  .dark-red 
  {
    background-color: rgba(255, 0, 0, 0.363) !important ;
    color: rgb(255, 255, 255);
    font-weight: 500;
    &:hover 
    {
      background-color: rgba(255, 0, 0, 0.555) !important ;
      color: white !important;
    }
  }