.teamdetail-parent {
    position: fixed;
    width: 100%;
    height: 100svh;
    background: #0000009a;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .teamdetail-container {
        max-width: 800px;
        height: 90svh;
        overflow-y: scroll;
        background-color: var(--white);
        padding: 20px;
        border-radius: 5px;

        p {
            background: var(--black);
            float: right;
            width: fit-content;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            padding: 5px;
            font-size: var(--xl-font);
            color: var(--white);
            cursor: pointer;
            margin-right: 3px;

        }

        form {
            height: fit-content;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: var(--gap);


            .text-input{
                width: 40%;
                padding: 0px !important;
                margin: 0px !important;
              
            }
        }
    }
}