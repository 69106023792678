.team-sheet {
    padding: 20px;
    width: fit-content;
    height: 100%;
    margin: auto;

    .handle-buttons {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .action-btn {
            font-size: var(--mid-font);
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            padding: 3px;
            border: none;
            background: transparent;
            cursor: pointer;
            &:hover 
            {
                &:nth-child(1)
                {
                   color: #2684ff !important;
                }
                &:nth-child(2)
                {
                   color: green !important;
                }
                &:nth-child(3)
                {
                   color: red !important;
                }
            }
        }
    }
}