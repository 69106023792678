@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
    --text: #cccccc;
    --accent: #2d2d2a;
    --light-grey: #4c4c47;
    --white: #fff;
    --black: #000;
    --blue: #EA7B22;
    --dark-blue: #EA7B22;
    --mid-font: 18px;
    --small-font: 14px;
    --large-font: 22px;
    --xl-font: 28px;
    --gap: 10px;
    --accent-grey: rgba(212, 211, 211, 0.473);
    --shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
    ;
}

*,
*::after,
*::before,
body,
html {
    padding: 0px;
    margin: 0px;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
}

.glass {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.21);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.btn {
    font-size: 16px;
    padding: 5px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 2px;
    background-color: var(--blue);
    color: var(--white);
    text-decoration: none;

    span {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 700;
    }
}

.glass {
    background: rgba(255, 255, 255, 0.21);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
}


.top-bar {
    // background: #000;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;

    span {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: fit-content;
        padding: 0px 20px;
        border-radius: 5px;
        font-size: 15px;
        font-weight: 500;

        &:nth-child(2) {
            background-color: rgb(252, 226, 226) !important;
            color: rgb(104, 4, 4);
        }

        &:nth-child(1) {
            background-color: rgb(253, 244, 201) !important;
            color: rgb(99, 89, 0);
            ;
        }

        &:nth-child(3) {
            background-color: rgb(228, 253, 228) !important;
            color: rgb(8, 37, 8);
        }
        &:nth-child(4) {
            background-color: rgb(228, 253, 228) !important;
            color: rgb(8, 37, 8);
        }
    }
}

.active-link {
    background-color: var(--white);
    color: var(--dark-blue) !important;
}

.version 
{
    width: 100%;
    text-align: center;
    color: white;
    font-weight: 500;
}