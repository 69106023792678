@import url('https://fonts.googleapis.com/css2?family=Fredericka+the+Great&family=Kalam:wght@300;400;700&display=swap');
.updates-wrapper {
    background: rgb(255, 255, 255);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .updates-container {
        width: 80%;
        height: 80%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(219, 218, 218);
        box-shadow: 0px 10px 10px rgb(112, 112, 112);

        .inner-div {
            background: #ffffff;
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            border-radius: 5px;
            color: rgb(0, 0, 0);
            padding: 20px 25px;
            h2 
            {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                color: red;
                gap: 5px;
                span 
                {
                    margin-top: 15px;
                }
            }
            & * {
//                 font-family: "Kalam", cursive;
//   font-weight: 400;
//   font-style: normal;
            }
            ol 
            {
                li 
                {
                    max-width: 80ch;
                    padding: 5px 0px;
                }
            }

           
        }
    }
}