.user-sheet 
{
    padding: 20px;
    width: fit-content;
    height: 100%;
    margin: auto;
    .handle-buttons3 {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .action-btn3 {
            font-size: var(--mid-font);
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            padding: 3px;
            border: none;
            background: transparent;
            cursor: pointer;
            &:hover 
            {
                color: red;
            }
        }
    }
}