.change-password {
    width: 100%;
    height: 100svh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in-out;
    .pass-err 
    {
        color: red;
        width: 100%;
        padding: 0px 10px;
    }
    form {
        background-color: #fff;
        border-radius: 5px;
        box-shadow: var(--shadow);
        animation: fadeIn 0.3s ease-in-out;
        padding: 20px 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;

        .close-btn {
            display: flex;
            width: 100%;
            justify-content: flex-end;

            span {
                background: var(--black);

                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                padding: 5px;
                font-size: var(--xl-font);
                color: var(--white);
                cursor: pointer;
            }
        }

        input {
            width: 230px;
        }

        .btns {
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .dlt-btn {
                padding: 3px 15px;
                font-size: var(--small-font);
                cursor: pointer;

            }
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}