.task-status-wrapper 
{
    position: fixed;
    width: 100%;
    height: 100svh;
    background: #0000009a;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    padding: 20px;
    margin: auto;
    .wrapper-back 
    {
        background: white;
        width: auto;
        height: 100%;
        
    .handle-buttons {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .action-btn {
            font-size: var(--mid-font);
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            padding: 3px;
            border: none;
            background: transparent;
            cursor: pointer;
            &:hover 
            {
                &:nth-child(1)
                {
                   color: #2684ff !important;
                }
                &:nth-child(2)
                {
                   color: green !important;
                }
                &:nth-child(3)
                {
                   color: red !important;
                }
            }
        }
    }
    }
}

.completed-row {
    background-color: rgb(243, 255, 243);
    color: rgb(8, 37, 8);
    font-weight: 500;
    &:hover 
    {
      background-color: rgb(228, 253, 228) !important;
      color: rgb(8, 37, 8);
    }
    .handle-buttons
    {
       button 
       {
        color: rgb(8, 37, 8)!important;
       }
    }
  }
.delayed-row {
    background-color: rgb(255, 240, 240);
    color: rgb(104, 4, 4);
    font-weight: 500;
    &:hover 
    {
      background-color: rgb(252, 226, 226) !important;
      color: rgb(104, 4, 4);
    }
    .handle-buttons
    {
       button 
       {
        color: rgb(104, 4, 4) !important;
       }
    }
  }
.pending-row {
    background-color: rgb(255, 252, 238) ;
    color: rgb(99, 89, 0);
    font-weight: 500;
    &:hover 
    {
      background-color: rgb(253, 244, 201) !important;
      color: rgb(99, 89, 0);;
    }
    .handle-buttons
    {
       button 
       {
        background-color: rgb(255, 252, 238);
        color: rgb(99, 89, 0);
       }
    }
  }

  .team-sheet 
  {
    display: flex;
  }